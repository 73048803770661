import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {env as environment} from '../controller/environment';
import { Context } from "../context";

const Login = (props) => {
    const {
		setUserDetail
	} = useContext(Context);
    const navigate = useNavigate();
    const storedData = localStorage.getItem('bank_user');
    if (storedData) {
        navigate("/dashboard");
    }
    const [validate, setValidate] = useState({
        userid: null,
        password: null,
        error: {
            user: null,
            pass: null,
        }
    });
    const userIdChange = (e) => {
        console.log(e.target.value);
        setValidate({...validate, userid: e.target.value,error:{user:null,pass:null}});
    }
    const passwordChange = (e) => {
        console.log(e.target.value);
        setValidate({...validate, password: e.target.value,error:{user:null,pass:null}});
    }
    const submitForm = (e) => {
        e.preventDefault();
        if(!validate.userid || validate.userid === null) {
            setValidate({...validate, error: {pass: null, user: "Please enter User ID"}});
        } else if(!validate.password || validate.password === null) {
            setValidate({...validate, error: { pass: "Please enter Password", user: null, }});
        } else {
            setValidate({...validate, error: {pass: null, user: null}});
            const data = { "key": validate.userid, timestamp: Date.now() + environment.cookieExpTime }; // 3600000 ms = 1 hour
            setUserDetail(data);
            localStorage.setItem('bank_user', JSON.stringify(data));
            navigate("/dashboard");
            // let checkUser = environment.user.filter((user) => {
            //     return user.userid === validate.userid;
            // });
            // if(checkUser.length) {
            //     if(checkUser[0].password == validate.password) {
            //         console.log("User found");
            //         setValidate({...validate, error: {pass: null, user: null}});
            //         const data = { "key": validate.userid, timestamp: Date.now() + environment.cookieExpTime }; // 3600000 ms = 1 hour
            //         setUserDetail(data);
            //         localStorage.setItem('bank_user', JSON.stringify(data));
            //         navigate("/dashboard");
            //     } else {
            //         setValidate({...validate, error: { pass: "Check your credentials", user: null, }});
            //     }
            // } else {
            //     setValidate({...validate, error: { pass: "Check your credentials", user: null, }});
            // }
            // console.log(validate.password);
        }
        
        // console.log(environment.user, validate, " Form submit");
        // document.cookie = `referral_key=hello;max-age=604800;domain=example.com`
    }
    return (
        <div className="login_bg">
        <div id="apz-message-info" className="pnt-ialt inf message sno" role="status" aria-live="polite">
          <ul>
            <li></li>
            <li>
              <h4 />
              <p />
            </li>
          </ul>
        </div>
        <div id="apz-message-warning" className="pnt-ialt war message sno" role="status" aria-live="polite">
          <ul>
            <li></li>
            <li>
              <h4 />
              <p />
            </li>
          </ul>
        </div>
        <div id="apz-message-error" className="pnt-ialt err message sno" role="status" aria-live="assertive">
          <ul>
            <li></li>
            <li>
              <h4 />
              <p />
            </li>
          </ul>
        </div>
        <div id="apz-message-success" className="pnt-ialt suc message sno" role="status" aria-live="polite">
          <ul>
            <li></li>
            <li>
              <h4 />
              <p />
            </li>
          </ul>
        </div>
        <div id="apzloader" className="sno" style={{position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#fff', zIndex: 99999, opacity: '0.2'}}>
          <div id="status" className="fa fa-spin-sp" style={{width: '200px', height: '200px', position: 'absolute', left: '50%', top: '50%', backgroundImage: 'url(apps/styles/themes/SuryodayRIB/img/loader.gif)', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', margin: '-100px 0 0 -100px'}}> &nbsp;</div>
        </div>
        <div id="pt-main">
          <div id="page_1">
            <div id="scr__RIBSUR__PreLogin_BaseScreen__main" className="rolepage">
              <div id="backdrop" className="backdrop" />
              <nav id="header" className="apz-nav apz-nav-horizontal pnb-head pnt-head apz-nav-open pri sno" headertype="FIXED" style={{}}>
                <div id="RIBSUR__PreLogin_BaseScreen__preLoginHeader" className="grb">
                  <div id="RIBSUR__PreLogin_BaseScreen__gr_col_6" className=" gcb-col12    " style={{}}>
                    <div id="RIBSUR__PreLogin_BaseScreen__pl_pnl_6_div" style={{}} className="plt-simp ma0">
                      <div id="RIBSUR__PreLogin_BaseScreen__ps_pls_6" className="pst-simp tsp pa0" style={{}}>
                        <div id="RIBSUR__PreLogin_BaseScreen__ct_frm_4" className="crt-form  hor pri">
                          <span id="RIBSUR__PreLogin_BaseScreen__sc_row_4" className="srb pri">
                            <span id="RIBSUR__PreLogin_BaseScreen__sc_col_5" className=" lft pri scb-col50">
                              <ul id="RIBSUR__PreLogin_BaseScreen__el_icn_4_ul" className="eoc srb">
                                <li className="etw-0">
                                  <label className="flb">&nbsp;</label>
                                </li>
                                <li id="RIBSUR__PreLogin_BaseScreen__el_icn_4_li" className="eic etw-100">
                                  <span id="RIBSUR__PreLogin_BaseScreen__el_icn_4" type="SVG" className="ett-imge" style={{}} title="Icon"></span>
                                </li>
                              </ul>
                            </span>
                            <span id="RIBSUR__PreLogin_BaseScreen__sc_col_6" className=" rht pri scb-col50">
                              <ul id="RIBSUR__PreLogin_BaseScreen__el_icn_5_ul" className="eoc srb">
                                <li className="etw-0">
                                  <label className="flb">&nbsp;</label>
                                </li>
                                <li id="RIBSUR__PreLogin_BaseScreen__el_icn_5_li" className="eic etw-100"></li>
                              </ul>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
              <section id="page-body" className="pagebody pnb-body pnt-body etw-100">
                <div id="RIBSUR__PreLogin_BaseScreen__baseDiv" className="grb">
                  <div id="scr__Login__Login__main">
                    <div id="Login__Login__gr_row_16" className="grb  login_bg">
                      <div id="Login__Login__gr_col_17" className=" gcb-sml12 gcb-med12 gcb-lar12 gcb-xxl12    " style={{}}>
                        <div id="Login__Login__pl_pnl_18_div" style={{}} className="plt-simp ma0">
                          <div id="Login__Login__ps_pls_20" className="pst-simp tsp pa0" style={{}}>
                            <div id="Login__Login__gr_row_18" className="grb  language-div">
                              <div id="Login__Login__gr_col_19" className=" gcb-col12    " style={{}}>
                                <div id="Login__Login__pl_pnl_20_div" style={{}} className="plt-simp ">
                                  <div id="Login__Login__ps_pls_22" className="pst-simp pri " style={{}}>
                                    <div id="Login__Login__ct_frm_1" className="crt-form  hor pri">
                                      <span id="Login__Login__sc_row_45" className="srb pri">
                                        <span id="Login__Login__sc_col_98" className=" rht pri scb-col90">&nbsp;</span>
                                        <span id="Login__Login__sc_col_99" className=" sno pri scb-col10">
                                          <ul id="Login__Login__language_ul" className="eoc srb">
                                            <li className="etw-0">
                                              <label id="Login__Login__language_grp_lbl" htmlFor="Login__Login__language" className="flb" style={{}} title="Dropdown">Dropdown</label>
                                            </li>
                                            <li id="Login__Login__language_li" className="eic etw-100">
                                              <div id="Login__Login__language_ext" className="etb-slct ett-slct  sec etw-100" enabled="enabled" style={{}}>
                                                <input id="Login__Login__language" className="sub-elt" type="text" readOnly="readonly" defaultValue="English" />
                                                <span id="Login__Login__language_span" className="sub-elt1"></span>
                                                <div id="Login__Login__language_div" className="sub-ctr" original-id="Login__Login__language">
                                                  <ul id="Login__Login__language_so" role="menu">
                                                    <li id="Login__Login__language_option_SUR_English" className=" is-selected" data-value="en" tabIndex={0} selected="selected">English</li>
                                                    <li id="Login__Login__language_option_SUR_Hindi" className data-value="hi" tabIndex={0}>हिन्दी</li>
                                                    <li id="Login__Login__language_option_SUR_Gujarati" className data-value="gu" tabIndex={0}>ગુજરાતી</li>
                                                    <li id="Login__Login__language_option_SUR_Kannada" className data-value="kn" tabIndex={0}>ಕನ್ನಡ</li>
                                                    <li id="Login__Login__language_option_SUR_Punjabi" className data-value="pa" tabIndex={0}>ਪੰਜਾਬੀ</li>
                                                    <li id="Login__Login__language_option_SUR_Odia" className data-value="odi" tabIndex={0}>ଓଡିଆ</li>
                                                    <li id="Login__Login__language_option_SUR_Marathi" className data-value="mr" tabIndex={0}>मराठी</li>
                                                    <li id="Login__Login__language_option_SUR_Tamil" className data-value="ta" tabIndex={0}>தமிழ்</li>
                                                    <li id="Login__Login__language_option_SUR_Telugu" className data-value="te" tabIndex={0}>తెలుగు</li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div id="Login__Login__webloginform" className="crt-form  ver aligncenter_full pri">
                                <form id="pt-main" onSubmit={submitForm}>
                                <span id="Login__Login__sc_row_13" className="srb wrapped pri">
                                    <span id="Login__Login__Login" className=" userloginsec pri scb-col100">
                                    <Link to={"/"}>
                                        <img src="./images/updated_logo.png" alt="logo" style={{width: "180px"}} />
                                    </Link>
                                    <span id="Login__Login__sc_row_16" className="srb wrapped pri mt1 mb1">
                                        <span id="Login__Login__sc_col_41" className=" pri scb-col100">
                                        <ul id="Login__Login__el_txt_3_ul" className="eoc srb wrapped">
                                            <li className="etw-0">
                                            <label id="Login__Login__el_txt_3_grp_lbl" htmlFor="Login__Login__el_txt_3" className="flb" style={{}} title>&nbsp;</label>
                                            </li>
                                            <li id="Login__Login__el_txt_3_li" className="eic etw-100">
                                            <p id="Login__Login__el_txt_3" className="ett-para pri fs26">
                                                <span id="Login__Login__el_txt_3_txtcnt">Sign In</span>
                                            </p>
                                            </li>
                                        </ul>
                                        </span>
                                    </span>
                                    <span id="Login__Login__sc_row_17" className="srb wrapped pri">
                                        <span id="Login__Login__sc_col_42" className=" lft position_infotxt pri scb-col100">
                                        <ul id="Login__Login__username_web_ul" className="eoc srb wrapped">
                                            <li className="etw-40">
                                            <label id="Login__Login__username_web_grp_lbl" htmlFor="Login__Login__username_web" className="flb req" style={{}} title="User ID">User ID</label>
                                            </li>
                                            <li id="Login__Login__username_web_li" className="eic etw-60">
                                            <span className="ecn etw-100" style={{}}>
                                                <input id="Login__Login__username_web" className="etw-100 ett-inpt pri lft" type="text" maxLength={30} placeholder="User ID" enabled="enabled" autoComplete="off" onChange={(e)=> userIdChange(e)} /> </span>
                                            <div className="text-danger">{validate.error.user}</div>
                                            </li>
                                        </ul>
                                        <ul id="Login__Login__username_webError_ul" className="eoc srb wrapped italic sno">
                                            <li className="etw-0">
                                            <label id="Login__Login__username_webError_grp_lbl" htmlFor="Login__Login__username_webError" className="flb" style={{}} title>&nbsp;</label>
                                            </li>
                                            <li id="Login__Login__username_webError_li" className="eic etw-100">
                                            <p id="Login__Login__username_webError" className="ett-para pri err fs13 var1">
                                                <span id="Login__Login__username_webError_txtcnt">Please Enter</span>
                                            </p>
                                            </li>
                                        </ul>
                                        </span>
                                    </span>
                                    <span id="Login__Login__sc_row_28" className="srb wrapped pri">
                                        <span id="Login__Login__sc_col_71" className=" lft position_infotxt pri scb-col100">
                                        <ul id="Login__Login__password_web_ul" className="eoc srb wrapped">
                                            <li className="etw-40">
                                                <label id="Login__Login__password_web_grp_lbl" htmlFor="Login__Login__password_web" className="flb req" style={{}} title="Password">Password</label>
                                                </li>
                                                <li id="Login__Login__password_web_li" className="eic etw-60">
                                                <span className="ecn etw-100" style={{}}>
                                                    <span className="etw-100 icr  pri">
                                                    <input id="Login__Login__password_web" className="etw-100 ett-inpt pri lft" type="password" maxLength={20} placeholder="Password" enabled="enabled" autoComplete="off" onChange={(e)=> passwordChange(e)} /> </span>
                                                </span>
                                                <div className="text-danger">{validate.error.pass}</div>
                                            </li>
                                        </ul>
                                        <ul id="Login__Login__password_webError_ul" className="eoc srb wrapped italic sno">
                                            <li className="etw-0">
                                            <label id="Login__Login__password_webError_grp_lbl" htmlFor="Login__Login__password_webError" className="flb" style={{}} title>&nbsp;</label>
                                            </li>
                                            <li id="Login__Login__password_webError_li" className="eic etw-100">
                                            <p id="Login__Login__password_webError" className="ett-para pri err fs13 var1">
                                                <span id="Login__Login__password_webError_txtcnt">Please Enter</span>
                                            </p>
                                            </li>
                                        </ul>
                                        </span>
                                    </span>
                                    <span id="Login__Login__sc_row_41" className="srb wrapped pri prelative mb1">
                                        <span id="Login__Login__sc_col_92" className=" pri scb-col100">
                                        <span id="Login__Login__sc_row_36" className="srb wrapped pri vkeyboard aligncenter">
                                            <span id="Login__Login__sc_col_82" className=" pri scb-col10">
                                            <ul id="Login__Login__keyBoardCheck_ul" className="eoc srb wrapped">
                                                <li className="etw-0">
                                                <label id="Login__Login__keyBoardCheck_grp_lbl" htmlFor="Login__Login__keyBoardCheck" className="flb" style={{}} title="Checkbox">Checkbox</label>
                                                </li>
                                                <li id="Login__Login__keyBoardCheck_li" className="eic etw-100">
                                                <span id="Login__Login__keyBoardCheck_span" className="etb-chek ett-chek hor pri">
                                                    <span id="Login__Login__keyBoardCheck_xlabelspan" htmlFor="Login__Login__keyBoardCheck" className></span>
                                                </span>
                                                </li>
                                            </ul>
                                            </span>
                                            <span id="Login__Login__sc_col_84" className=" pri scb-col90">
                                            <ul id="Login__Login__virtualLink_ul" className="eoc srb wrapped">
                                                <li className="etw-0"></li>
                                                <li id="Login__Login__virtualLink_li" className="eic etw-100">
                                                <p id="Login__Login__virtualLink" className="ett-para pri fs14 var2" style={{textDecoration: 'none'}}></p>
                                                </li>
                                            </ul>
                                            </span>
                                        </span>
                                        <span id="Login__Login__virtualKeypadRow" className="srb wrapped pri virtualkeypadrow sno">
                                            <span id="Login__Login__sc_col_93" className=" rht v-keybord-close pri scb-col5">
                                            <ul id="Login__Login__el_icn_1_ul" className="eoc srb wrapped">
                                                <li className="etw-0">
                                                <label className="flb">&nbsp;</label>
                                                </li>
                                                <li id="Login__Login__el_icn_1_li" className="eic etw-100"></li>
                                            </ul>
                                            </span>
                                        </span>
                                        </span>
                                    </span>
                                    <span id="Login__Login__SecureImage_row" className="srb wrapped pri inline-box aligncenter sno">
                                        <span id="Login__Login__sc_col_83" className=" pri scb-col15">
                                        <ul id="Login__Login__SecureCheck_ul" className="eoc srb wrapped">
                                            <li className="etw-0">
                                            <label id="Login__Login__SecureCheck_grp_lbl" htmlFor="Login__Login__SecureCheck" className="flb" style={{}} title="Checkbox">Checkbox</label>
                                            </li>
                                            <li id="Login__Login__SecureCheck_li" className="eic etw-100">
                                            <span id="Login__Login__SecureCheck_span" className="etb-chek ett-chek hor pri">
                                                <span id="Login__Login__SecureCheck_xlabelspan" htmlFor="Login__Login__SecureCheck" className>
                                                <input id="Login__Login__SecureCheck" aria-describedby aria-labelledby="Login__Login__SecureCheck_lbl" className type="CHECKBOX" enabled="enabled" defaultValue checkedval="y" uncheckedval="n" indeterminateval="i" />
                                                <label id="Login__Login__SecureCheck_lbl" className="flb" htmlFor="Login__Login__SecureCheck" />
                                                </span>
                                            </span>
                                            </li>
                                        </ul>
                                        </span>
                                        <span id="Login__Login__sc_col_85" className=" pri scb-col65">
                                        <ul id="Login__Login__el_txt_13_ul" className="eoc srb wrapped">
                                            <li className="etw-0">
                                            <label id="Login__Login__el_txt_13_grp_lbl" htmlFor="Login__Login__el_txt_13" className="flb" style={{}} title>&nbsp;</label>
                                            </li>
                                            <li id="Login__Login__el_txt_13_li" className="eic etw-100">
                                            <p id="Login__Login__el_txt_13" className="ett-para pri fs14 var2">
                                                <span id="Login__Login__el_txt_13_txtcnt">Confirm image for secure login</span>
                                            </p>
                                            </li>
                                        </ul>
                                        </span>
                                        <span id="Login__Login__sc_col_90" className=" pl1 pri scb-col20">
                                        <ul id="Login__Login__userSecurityImage_ul" className="eoc srb wrapped">
                                            <li className="etw-0">
                                            <label className="flb">&nbsp;</label>
                                            </li>
                                            <li id="Login__Login__userSecurityImage_li" className="eic etw-100">
                                            <img id="Login__Login__userSecurityImage" src="https://ib.suryodaybank.com/SuryodayRIB/" className="ett-imge cir  tb52 " style={{}} title />
                                            <span id="Login__Login__userSecurityImage_svg" type="SVG" className="sno" style={{}} title />
                                            </li>
                                        </ul>
                                        </span>
                                    </span>
                                    <span id="Login__Login__CheckImageErrorDiv" className="srb wrapped pri sno error_msg">
                                        <span id="Login__Login__sc_col_91" className=" pri scb-col100">
                                        <ul id="Login__Login__el_txt_15_ul" className="eoc srb wrapped italic">
                                            <li className="etw-0">
                                            <label id="Login__Login__el_txt_15_grp_lbl" htmlFor="Login__Login__el_txt_15" className="flb" style={{}} title>&nbsp;</label>
                                            </li>
                                            <li id="Login__Login__el_txt_15_li" className="eic etw-100">
                                            <p id="Login__Login__el_txt_15" className="ett-para pri err fs13 var1">
                                                <span id="Login__Login__el_txt_15_txtcnt">Please confirm secure Image and Text</span>
                                            </p>
                                            </li>
                                        </ul>
                                        </span>
                                    </span>
                                    <span id="Login__Login__sc_row_31" className="srb wrapped pri">
                                        <span id="Login__Login__sc_col_78" className=" pri scb-col100">
                                        <ul id="Login__Login__loginButton_ul" className="eoc srb wrapped">
                                            <li className="etw-0">
                                            <label className="flb">&nbsp;</label>
                                            </li>
                                            <li id="Login__Login__loginButton_li" className="eic etw-100">
                                            <button id="Login__Login__loginButton" type="submit" className="ett-bttn etw-100 pri med" enabled="enabled">
                                                <span id="Login__Login__loginButton_txtcnt">Continue to Login</span>
                                            </button>
                                            </li>
                                        </ul>
                                        </span>
                                    </span>
                                    <span id="Login__Login__sc_row_38" className="srb wrapped pri mtop10">
                                        <span id="Login__Login__sc_col_86" className=" pri scb-col100">
                                        <ul id="Login__Login__el_btn_6_ul" className="eoc srb wrapped">
                                            <li className="etw-0">
                                            <label className="flb">&nbsp;</label>
                                            </li>
                                            <li id="Login__Login__el_btn_6_li" className="eic etw-100">
                                            <button id="Login__Login__el_btn_6" type="button" className="ett-bttn etw-100 pri med inf" enabled="enabled">
                                                <span id="Login__Login__el_btn_6_txtcnt">New User Sign Up</span>
                                            </button>
                                            </li>
                                        </ul>
                                        </span>
                                    </span>
                                    <span id="Login__Login__sc_row_40" className="srb wrapped pri inline-forgot">
                                        <span id="Login__Login__sc_col_88" className=" pri scb-col50">
                                        <ul id="Login__Login__el_hpl_1_ul" className="eoc srb wrapped">
                                            <li className="etw-0">
                                            <label className="flb">&nbsp;</label>
                                            </li>
                                            <li id="Login__Login__el_hpl_1_li" className="eic etw-100">
                                            <a id="Login__Login__el_hpl_1" className="ett-hypl pri" href="javascript:" aria-label="Hyperlink" role="button">
                                                <span id="Login__Login__el_hpl_1_txtcnt">Forgot Password?</span>
                                            </a>
                                            </li>
                                        </ul>
                                        </span>
                                        <span id="Login__Login__sc_col_89" className=" rht position_infotxt pri scb-col50">
                                        <ul id="Login__Login__el_hpl_2_ul" className="eoc srb wrapped">
                                            <li className="etw-0">
                                            <label className="flb">&nbsp;</label>
                                            </li>
                                            <li id="Login__Login__el_hpl_2_li" className="eic etw-100">
                                            <a id="Login__Login__el_hpl_2" className="ett-hypl pri" href="javascript:" aria-label="Hyperlink" role="button">
                                                <span id="Login__Login__el_hpl_2_txtcnt">Forgot Username?</span>
                                            </a>
                                            </li>
                                        </ul>
                                        </span>
                                    </span>
                                    <span id="Login__Login__sc_row_47" className="srb wrapped pri inline-forgot">
                                        <span id="Login__Login__sc_col_102" className=" pri scb-col50">
                                        <ul id="Login__Login__el_hpl_5_ul" className="eoc srb wrapped">
                                            <li className="etw-0">
                                            <label className="flb">&nbsp;</label>
                                            </li>
                                            <li id="Login__Login__el_hpl_5_li" className="eic etw-100">
                                            <a id="Login__Login__el_hpl_5" className="ett-hypl pri" href="javascript:" aria-label="Hyperlink" role="button">
                                                <span id="Login__Login__el_hpl_5_txtcnt">Security Tips</span>
                                            </a>
                                            </li>
                                        </ul>
                                        </span>
                                        <span id="Login__Login__sc_col_103" className=" rht position_infotxt pri scb-col50">
                                        <ul id="Login__Login__el_hpl_6_ul" className="eoc srb wrapped">
                                            <li className="etw-0">
                                            <label className="flb">&nbsp;</label>
                                            </li>
                                            <li id="Login__Login__el_hpl_6_li" className="eic etw-100">
                                            <a id="Login__Login__el_hpl_6" className="ett-hypl pri" href="javascript:" aria-label="Hyperlink" role="button">
                                                <span id="Login__Login__el_hpl_6_txtcnt">Privacy Policy</span>
                                            </a>
                                            </li>
                                        </ul>
                                        </span>
                                    </span>
                                    <span id="Login__Login__sc_row_46" className="srb wrapped pri inline-forgot">
                                        <span id="Login__Login__sc_col_101" className=" cen position_infotxt pri scb-col100">
                                        <ul id="Login__Login__el_hpl_4_ul" className="eoc srb wrapped">
                                            <li className="etw-0">
                                            <label className="flb">&nbsp;</label>
                                            </li>
                                            <li id="Login__Login__el_hpl_4_li" className="eic etw-100">
                                            <a id="Login__Login__el_hpl_4" className="ett-hypl pri" href="javascript:" aria-label="Hyperlink" role="button">
                                                <span id="Login__Login__el_hpl_4_txtcnt">Digital Payments Security</span>
                                            </a>
                                            </li>
                                        </ul>
                                        </span>
                                    </span>
                                    </span>
                                </span>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="Login__Login__otp_div" className="grb">
                      <div id="Login__Login__gr_col_20" className=" gcb-col12    " style={{}}>
                        <div id="Login__Login__pl_pnl_21_div" style={{}} className="plt-simp ma0">
                          <div id="Login__Login__ps_pls_23" className="pst-simp tsp pa0" style={{}} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div id="RIBSUR_PreLogin_BaseScreentooltip" className="sno" style={{width: '200px', position: 'absolute'}}>
                <p id="tooltext" />
              </div>
            </div>
          </div>
          <div id="page_2" className="sno"></div>
        </div>
      </div>
    )
}
export default Login;
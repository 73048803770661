import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {env as environment} from '../controller/environment';
import { Context } from "../context";

const Login = (props) => {
    const {
		setUserDetail
	} = useContext(Context);
    const navigate = useNavigate();
    const [validate, setValidate] = useState({
        userid: null,
        password: null,
        error: {
            user: null,
            pass: null,
        }
    });
    const userIdChange = (e) => {
        console.log(e.target.value);
        setValidate({...validate, userid: e.target.value,});
    }
    const passwordChange = (e) => {
        console.log(e.target.value);
        setValidate({...validate, password: e.target.value,});
    }
    const submitForm = (e) => {
        e.preventDefault();
        if(!validate.userid || validate.userid === null) {
            setValidate({...validate, error: {pass: null, user: "Please enter User ID"}});
        } else if(!validate.password || validate.password === null) {
            setValidate({...validate, error: {pass: "Please enter Password", user: null}});
        } else {
            let checkUser = environment.user.filter((user) => {
                return user.userid === validate.userid;
            });
            if(checkUser.length) {
                if(checkUser[0].password == validate.password) {
                    console.log("User found");
                    setValidate({...validate, error: {pass: null, user: null}});
                    const data = { "key": validate.userid, timestamp: Date.now() + environment.cookieExpTime }; // 3600000 ms = 1 hour
                    setUserDetail(data);
                    localStorage.setItem('bank_user', JSON.stringify(data));
                    navigate("/dashboard");
                }
            } else {
                setValidate({...validate, error: {pass: "Check your credentials", user: null}});
            }
            console.log(validate.password);
        }
        
        // console.log(environment.user, validate, " Form submit");
        // document.cookie = `referral_key=hello;max-age=604800;domain=example.com`
    }
    const storedData = localStorage.getItem('bank_user');
    
    useEffect(() => {
      if (storedData) {
        navigate("/dashboard");
      }
    },[]);
    return (
        <div className="home">
          <div className="container-fluid nopads" id="head_top">
            <div className="container full_wid nopads" style={{marginTop: "0"}}>
              {/*Header div starts*/}
              <div className="headbox">
                <div className="logo col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <a>
                    <img src="./images/updated_logo.png" alt="logo" />
                  </a>
                </div> 
                <div className="col-sm-12 col-md-5 col-lg-3 col-xs-12 bord_leftt no_pad_req" style={{border: "none"}}>
                  <div className="tollfrebox">
                    <span className="phoneicon">
                    </span>
                    <span className="phonenum"> 
                      <span className="ionicon new-ionicon hidden visible-xs phone_close pull-left">
                      </span>
                    </span>
                  </div>
                  <div className="open_acc_abslt hidden-md hidden-lg hidden-xs" style={{top: '75px'}}>
                    <a aria-label="Apply">
                      <span className="accnt-i ionicon">
                      </span>
                      <span className="accnt-txt">Apply Now
                      </span>
                      <span className="birdsimg">
                      </span>
                    </a>
                  </div>
                  <div className="logindivbutons">
                    <div className="elogin-btn">
                      <span className="elognbx">
                        <a className="corporate-ebanki" >
                          <span className="i-logn ionicon">
                          </span>
                          <span className="i-txt">Corporate <br /> eBanking
                          </span>
                        </a>
                      </span> 
                    </div>
                    <div className="elogin-btn">
                      <span className="elognbx">
                        <a className="corporate-ebanki" >
                          <span className="i-logn ionicon">
                          </span>
                          <span className="i-txt"><span className><b>New Retail<br />eBanking</b>
                            </span></span>
                        </a>
                      </span> 
                    </div>
                  </div>
                  <div className="open_acc_abslt hidden-md hidden-lg hidden-sm" style={{top: '75px'}}>
                    <a >
                      <span className="accnt-i ionicon">
                      </span>
                      <span className="accnt-txt">Apply Now
                      </span>
                      <span className="birdsimg">
                      </span>
                    </a>
                  </div>
                  <div className="blck_bgg2">
                  </div>
                </div> 
                <div className="headrlnks col-sm-12 col-md-4 col-lg-6 ">
                  <div className="nopads">
                    <Link className="elogin-btn hidden-xs hidden-sm" to={"/login"}>
                      <span className="i-txt"> <span className><b> &nbsp;&nbsp;eBanking</b>
                        </span></span>
                    </Link>
                    <div className="login_box" style={{display: 'none'}}>
                      <div className="close_container">
                        <div className="close_menu_pop">
                          <img src="./SuryodayBank _ Home_files/icon-close-128.png" className="img-responsive" />
                        </div>
                      </div>
                      <div className="log_head">
                        Retail eBanking               
                      </div>
                    </div>
                    <div className="open_acc_abslt hidden-xs hidden-sm" style={{top: '75px'}}>
                      <a >
                        <span className="accnt-i ionicon">
                        </span>
                        <span className="accnt-txt">Apply Now
                        </span>
                        <span className="birdsimg">
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>  
              {/*Header div ends*/}
            </div>  
          </div>
          <div className="clear" />
          <div className="black_overl" />   
          <div className="mob_menu_wrap" style={{height: '503px'}}>
            <ul>
              <li className="frst_lvl">
                <span className="li-icon li-third" /><span className="li-name">Accounts &amp; Cards</span>
                <span className="dropdwn ionicon"></span><span className="ionicon dropdwn2"></span>
                <ul>
                  <li className="givheght">
                    <a >
                      <span className="li-icon li-third" /><span className="li_txt">Savings Accounts</span>
                    </a>
                  </li>
                  <li className="givheght">
                    <a>
                      <span className="li-icon li-third" /><span className="li_txt">Share Your Smile Savings Account</span>
                    </a>
                  </li>
                  <li className="givheght">
                    <a>
                      <span className="li-icon li-third" /><span className="li_txt">Next Gen Savings Account</span>
                    </a>
                  </li>
                  <li className="givheght">
                    <a>
                      <span className="li-icon li-third" /><span className="li_txt">Blossom Women Savings Account</span>
                    </a>
                  </li>
                  <li className="givheght">
                    <a>
                      <span className="li-icon li-third" /><span className="li_txt">Salary Savings Accounts</span>
                    </a>
                  </li>
                  <li className="givheght">
                    <a>
                      <span className="li-icon li-third" /><span className="li_txt">Current Accounts</span>
                    </a>
                  </li>
                </ul>
                <div className="clear" />
              </li>
              <li className="frst_lvl">
                <span className="li-icon li-third" /><span className="li-name">Term Deposits</span>
                <span className="dropdwn ionicon"></span><span className="ionicon dropdwn2"></span>
                <ul>
                  <li className="givheght">
                    <a>
                      <span className="li-icon li-first" /><span className="li_txt">Non-Callable Fixed Deposit-Retail</span>
                    </a>
                  </li>
                  <li className="givheght">
                    <a>
                      <span className="li-icon li-first" /><span className="li_txt">Tax Saver Fixed Deposits</span>
                    </a>
                  </li>
                  <li className="givheght">
                    <a>
                      <span className="li-icon li-first" /><span className="li_txt">Domestic Fixed Deposits</span>
                    </a>
                  </li>
                  <li className="givheght">
                    <a>
                      <span className="li-icon li-first" /><span className="li_txt">Recurring Deposits</span>
                    </a>
                  </li>
                  <li className="givheght">
                    <a>
                      <span className="li-icon li-first" /><span className="li_txt">Form 15G/H </span>
                    </a>
                  </li>
                </ul>
                <div className="clear" />
              </li>
              <li className="frst_lvl">
                <span className="li-icon li-third" /><span className="li-name">Cards</span>
                <span className="dropdwn ionicon"></span><span className="ionicon dropdwn2"></span>
                <ul>
                  <li className="givheght">
                    <a>
                      <span className="li-icon li-third" /><span className="li_txt">Debit Cards</span>
                    </a>
                  </li>
                </ul>
                <div className="clear" />
              </li>
              {/* LOANS SECTION FOR MOBILE VIEW */}
              <li className="frst_lvl">
                <span className="li-icon li-second" />
                <span className="li-name"><a>Loans</a></span>
                <span className="ionicon dropdwn2"></span>
                <div className="clear" />
              </li>
              {/* nri new start */}
              <li className="frst_lvl">
                <span className="li-icon li-first" /><span className="li-name">NRI Banking</span>
                <span className="dropdwn ionicon"></span><span className="ionicon dropdwn2"></span>
                <ul>
                  <li className="givheght">
                    <a >
                      <span className="li-icon li-first" /><span className="li_txt">Non-Resident Fixed Deposit</span>
                    </a>
                  </li>
                  <li className="givheght">
                    <a >
                      <span className="li-icon li-first" /><span className="li_txt">Non-Resident Savings Account</span>
                    </a>
                  </li>
                </ul>   
                <div className="clear" />
              </li>
              {/* nri end new */}
              {/* INVESTMENT AND INSURANCE SECTION FOR MOBILE VIEW */}
              <li className="frst_lvl">
                <span className="li-icon li-first" /><span className="li-name">Investment and Insurance</span>
                <span className="dropdwn ionicon"></span><span className="ionicon dropdwn2"></span>
                <ul>
                  <li className="givheght">
                    <a >
                      <span className="li-icon li-first" /><span className="li_txt">Life Insurance</span>
                    </a>
                  </li>
                  <li className="givheght">
                    <a>
                      <span className="li-icon li-first" /><span className="li_txt">General Insurance</span>
                    </a>
                  </li>
                </ul>   
                <div className="clear" />
              </li>
              <li className="frst_lvl">
                <span className="li-icon li-first" /><span className="li-name">Investments</span>
                <span className="dropdwn ionicon"></span><span className="ionicon dropdwn2"></span>
                <ul>
                  <li className="givheght">
                    <a>
                      <span className="li-icon li-first" /><span className="li_txt">National Pension Scheme(NPS)</span>
                    </a>
                  </li>
                </ul>
                <div className="clear" />
              </li>
              <li className="frst_lvl">
                <span className="li-icon li-first" /><span className="li-name">Social Security Scheme</span>
                <span className="dropdwn ionicon"></span><span className="ionicon dropdwn2"></span>
                <ul>
                  <li className="givheght">
                    <a>
                      <span className="li-icon li-first" /><span className="li_txt">Atal Pension Yojana</span>
                    </a>
                  </li>
                  <li className="givheght">
                    <a>
                      <span className="li-icon li-first" /><span className="li_txt">Pradhan Mantri Jeevan Jyoti Bima</span>
                    </a>
                  </li>
                  <li className="givheght">
                    <a>
                      <span className="li-icon li-first" /><span className="li_txt">Pradhan Mantri Suraksha Bima </span>
                    </a>
                  </li>
                </ul>
                <div className="clear" />
              </li>
              {/* END OF INVESTMENT AND INSURANCE SECTION FOR MOBILE VIEW */}
              <li className="givheght">
                <a>
                  <span className="li-icon li-fourth" /><span className="li-name">Locate Us</span>
                </a>
              </li>
              <li className="givheght">
                <a>
                  <span className="li-icon li-fifth" /><span className="li-name">About Us</span>
                </a>
              </li>
              <li className="givheght">
                <a>
                  <span className="li-icon li-sixth" /><span className="li-name">Careers</span>
                </a>
              </li>
              <li className="givheght">
                <a>
                  <span className="li-icon li-seven" /><span className="li-name">Media News</span>
                </a>
              </li>
              <li className="givheght">
                <a>
                  <span className="li-icon li-eight" /><span className="li-name">Financials</span>
                </a>
              </li>
            </ul>
          </div>
          {/* Navigation div ends */}
          <div className="container-fluid nopads navbg" id="head_menu">
            <div className="container nopads full_wid" style={{marginTop: "6px"}}>
              <div className="navdiv">
                <div className="col-sm-12 col-md-2 col-lg-2 col-xs-12 navdiv1">
                  <span className="ionicon"></span><span className="marg-mins">I am looking for </span>
                  <span className="trangleicn" />
                </div>
                <div className="col-sm-8 col-lg-8 navdiv2 nopads">
                  <ul className="fisrtab">
                    <li className="sub-li main_tab">
                      <div>
                        <span className="li-icon li-third" />
                        <span className="li-name">Accounts &amp; Cards</span>
                      </div>
                    </li>
                    <li className="sub-li">
                      <div>
                        <span className="li-icon li-second" />
                        <span className="li-name"><a>Loans</a></span>
                      </div>
                    </li>
                    <li className="sub-li">
                      <div>
                        <span className="li-icon li-second" />
                        <span className="li-name">NRI Banking</span>
                      </div>
                    </li>
                    <li className="sub-li">
                      <div>
                        <span className="li-icon li-third" />
                        <span className="li-name">Investment and Insurance</span>
                      </div>
                    </li>
                    {/*  Investment and Insurance changes End on 24-12-19 */}
                    <li className="sub-li difrnt">
                      <div>
                        <a data-ref="https://locate.littlerockbank.com/">
                          <span className="li-icon li-fourth" /><span className="li-name ">Locate Us</span>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
                <a data-ref="https://www.littlerock.com/account/signup">
                  <div className="col-md-2 col-lg-2 navdiv3 hidden-xs hidden-sm">
                    <span className="accnt-txt" style={{textAlign: "center", float: "none", display:"block", padding:"10px"}}>Apply Now</span>
                  </div>
                </a>
              </div>
              {/*Navigation div ends*/}
            </div>
          </div>
          {/*Banner code starts*/}
          <div className="new_banr">
            <div id="myCarousel" className="carousel slide" data-ride="carousel">
              {/* Indicators */}
              {/* Wrapper for slides */}
              <div className="carousel-inner">
                <div className="item">
                  <a data-ref="https://www.littlerock.com/account/signup">
                    <img src="./SuryodayBank _ Home_files/RD_WebBanner-v2-01.jpg" className="img-responsive" />
                  </a>
                </div>
                {/*<div class="item">
                          <a href="account/signup">
                              <img src="https://www.littlerock.com/assets/images/banners/CV_Suryoday_Digital_week_web_banner.jpg" class="img-responsive">
                          </a>
                      </div>*/}
                <div className="item">
                  <a data-ref="https://www.littlerock.com/">
                    <img src="./SuryodayBank _ Home_files/CV_Suryoday_Congrats-GoldAward_Web-banner_02.jpg" className="img-responsive" />
                  </a>
                </div>
                <div className="item">
                  <a data-ref="https://www.littlerock.com/deposits/fixed-deposit/rate-of-interest">
                    <img src="./SuryodayBank _ Home_files/NewFDRate(9.1)7_August_2023_WebBanner.jpg" className="img-responsive" />
                  </a>	
                </div>
                <div className="item">
                  <a data-ref="https://www.littlerock.com/rate-of-interest">
                    <img src="./SuryodayBank _ Home_files/2023-SA_WebBanner-01.jpg" className="img-responsive" />
                  </a>
                </div>
                <div className="item">
                  <img src="./SuryodayBank _ Home_files/CV_Suryoday_Blossom_Web_banner.jpg" className="img-responsive" />
                  <a className="banner-link1" data-ref="https://www.littlerock.com/account/signup" />
                  <a className="banner-link2" data-ref="https://www.littlerock.com/account/Blossom_Women_Savings_Account" />
                </div>
                <div className="item">
                  <a data-ref="https://www.littlerock.com/index.php/RBI-Ombudsman">
                    <img src="./SuryodayBank _ Home_files/CV_Suryoday_Web-Banner.jpg" className="img-responsive" />
                  </a>
                </div>
                <div className="item">
                  <a data-ref="https://www.littlerock.com/upi_blog">
                    <img src="./SuryodayBank _ Home_files/UPI_Fradu_Web_banner.jpg" className="img-responsive" />
                  </a>
                </div>
                <div className="item">
                  <img src="./SuryodayBank _ Home_files/G20_WEB_BANNER_01.jpg" className="img-responsive" />
                </div>
                <div className="item">
                  <a data-ref="https://www.littlerock.com/index.php/minor">
                    <img src="./SuryodayBank _ Home_files/Next_Gen_Website_Banner.jpg" className="img-responsive" />
                  </a>
                </div> 
                <div className="item active left">
                  <a data-ref="https://www.littlerock.com/index.php/accounts/savings">
                    <img src="./SuryodayBank _ Home_files/healthandwellness-01.jpg" className="img-responsive" />
                  </a>
                </div>
                <div className="item next left">
                  <a data-ref="https://www.littlerock.com/assets/pdf/Suryoday-Positive-Pay-System-Mailer-and-Website-Content.pdf">
                    <img src="./images/home_banner.png" className="img-responsive" alt="banner" />
                  </a>
                </div>
                <div className="item">
                  <a data-ref="https://www.littlerock.com/index.php/nps">
                    <img src="./SuryodayBank _ Home_files/nps_5.jpg" className="img-responsive" />
                  </a>
                </div>	
              </div>
            </div>
          </div>
          <div className="mobilebanner show-xs hidden-sm hidden-md hidden-lg">
            <div id="myCarouselmb" className="carousel slide" data-ride="carousel" data-interval={2000}>
              {/* Indicators */}
              <ol className="carousel-indicators bx-pager">
                <li data-target="#myCarouselmb" data-slide-to={0} className="active" />
                <li data-target="#myCarouselmb" data-slide-to={1} />
                <li data-target="#myCarouselmb" data-slide-to={2} />
                <li data-target="#myCarouselmb" data-slide-to={3} />
                <li data-target="#myCarouselmb" data-slide-to={4} />
                <li data-target="#myCarouselmb" data-slide-to={5} />
                <li data-target="#myCarouselmb" data-slide-to={6} /> 
                <li data-target="#myCarouselmb" data-slide-to={7} />
                <li data-target="#myCarouselmb" data-slide-to={8} />
                <li data-target="#myCarouselmb" data-slide-to={9} />
                <li data-target="#myCarouselmb" data-slide-to={10} />
                <li data-target="#myCarouselmb" data-slide-to={11} />
                {/*<li data-target="#myCarouselmb" data-slide-to="12"></li>*/}
              </ol>
              {/* Wrapper for slides */}
              <div className="carousel-inner">
                <div className="item active">
                  <a data-ref="https://www.littlerock.com/account/signup">
                    <img src="./SuryodayBank _ Home_files/RD_MobileBanner-v2-01.jpg" className="img-responsive" />
                  </a>
                </div>
                {/*<div class="item">
                          <a href="account/signup">
                              <img src="https://www.littlerock.com/assets/images/banners/Digital_week_mobile_banner_01.jpg" class="img-responsive">
                          </a>
                      </div> */}
                <div className="item">
                  <a>
                    <img src="./SuryodayBank _ Home_files/CV_Suryoday_Congrats-GoldAward_Mobile banner_03.jpg" className="img-responsive" />
                  </a>
                </div> 
                <div className="item">
                  <a data-ref="https://www.littlerock.com/deposits/fixed-deposit/rate-of-interest">
                    <img src="./SuryodayBank _ Home_files/NewFDRate(9.1)7_August 2023_MobileBanner.jpg" className="img-responsive" />
                  </a>
                </div>
                <div className="item">
                  <a data-ref="https://www.littlerock.com/rate-of-interest">
                    <img src="./SuryodayBank _ Home_files/2023-SA_MobileBanner-01.jpg" className="img-responsive" />
                  </a>
                </div>
                <div className="item">
                  <a data-ref="https://www.littlerock.com/account/Blossom_Women_Savings_Account">
                    <img src="./SuryodayBank _ Home_files/CV_Suryoday_Mobile_Banner.jpg" className="img-responsive" />
                  </a>
                </div>
                <div className="item">
                  <a data-ref="https://www.littlerock.com/index.php/RBI-Ombudsman">
                    <img src="./SuryodayBank _ Home_files/Mobile-Banner-01.jpg" className="img-responsive" />
                  </a>
                </div>	
                <div className="item">
                  <a data-ref="https://www.littlerock.com/upi_blog">
                    <img src="./SuryodayBank _ Home_files/UPI_Fradu_Mobile_Banner.jpg" className="img-responsive" />
                  </a>
                </div>	
                <div className="item">
                  <a>
                    <img src="./SuryodayBank _ Home_files/G20_WEB_BANNER_02.jpg" className="img-responsive" />
                  </a>
                </div> 	
                <div className="item">
                  <a data-ref="https://www.littlerock.com/index.php/minor">
                    <img src="./SuryodayBank _ Home_files/Next_Gen_Mobile_Website.jpg" className="img-responsive" />
                  </a>
                </div>
                <div className="item">
                  <a data-ref="https://www.littlerock.com/index.php/accounts/savings">
                    <img src="./SuryodayBank _ Home_files/healthandwellness-02.jpg" className="img-responsive" />
                  </a>
                </div>
                <div className="item">
                  <a data-ref="https://www.littlerock.com/assets/pdf/Suryoday-Positive-Pay-System-Mailer-and-Website-Content.pdf">
                    <img src="./SuryodayBank _ Home_files/MAKING-CHEQUE-PAYMENTS-SECURE-WITH-POSITIVE-PAY-SYSTEM-02.jpg" className="img-responsive" />
                  </a>
                </div>
                <div className="item">
                  <a data-ref="https://www.littlerock.com/index.php/smile_saving_account">
                    <img src="./SuryodayBank _ Home_files/Share_your_Smile_Mobile.jpg" className="img-responsive" />
                  </a>
                </div>
                <div className="item">
                  <a data-ref="https://www.littlerock.com/index.php/nps">
                    <img src="./SuryodayBank _ Home_files/mobile_banner_nps_5.jpg" className="img-responsive" />
                  </a>
                </div>	
              </div>
            </div>
          </div>
          {/*Banner code ends*/}
          <div className="clear" />
          {/* About Us Start */}
          <a data-ref="https://www.littlerock.com/about-us"><div className="abtuslbl"><span>About Us</span></div></a>
          {/* About Us End */}
          {/* Modal Div Start */}
          <div id="myModal" className="modal">
            {/* Modal content */}
            <div className="modal-content">
              <span className="close">�</span>
              <p id="demo" />
            </div>
          </div>
          {/* Modal Div End */}
          {/* Button trigger modal */}
          {/*
  <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
    Launch demo modal
  </button> */}
          {/* Modal */}
          <style type="text/css" nonce dangerouslySetInnerHTML={{__html: "\n  .blinking{\n    animation:blinkingText 0.4s infinite;\n  }\n  @keyframes blinkingText{\n    0%{color:#363794;}\n    49%{color:#ea8319;}\n    50%{color:#363794;}\n    99%{color: #363794;}\n    100%{color: #363794;}\n  }\n  .divwidth{width:100%;}\n  .mq_tag{color:white;font-weight:500;background: #f57e20;}\n  .padd-bots{padding-bottom: 36px;}\n  .padtopsb{padding-top: 10px;}\n   .newmblstyle{ \n\t font-size: 12px;\n\tcolor: #6c6c6c;\n\tpadding: 10px;\n\tdisplay: inline-block;\n\tfloat: right;\n\t}\n\n\t.watermark {\n\t  transition: .5s ease;\n\t  /* opacity: 1000; */\n\t  position: absolute;\n\t  top: 50%;\n\t  left: 50%;\n\t  opacity: 0.3;\n\t  transform: translate(-50%, -50%);\n\t  -ms-transform: translate(-50%, -50%);\n\t  text-align: center;\n\t  z-index: -9999;\n\t}\n\t.watermark {\n\t  /* opacity: 1000; */\n\t  display: block;\n\t  width: 100%;\n\t  height: auto;\n\t  transition: .5s ease;\n\t  backface-visibility: hidden;\n\t  z-index: -9999;\n\t}\n\t#pop_content{\n\t  width: 75% !important;\n\t  margin-inline: auto;\n\t  /* margin-left:15%; */\n\t}\n\t#close_img{\n\n\t  /*margin-bottom:-70px !important;\n\t  height:85px;\n\t  color:white;\n\t  margin-right:129px;\n\t  font-size: 37px;*/\n\t\tposition: absolute;\n\t\tright: 5%;\n\t\ttop: 1%;\n\t\tfont-size: 40px;\n\t\tcolor:#343794;\n\t}\n\t#imgclass{\n\t  height: auto;\n\t  width: auto;\n\t  margin:0 auto;\n\t}\n\t.modalimg {position: fixed; top: 50%; left: 50%;  transform: translate(-50%, -50%);}\n\t@media screen and (max-width:767.90px) {\n\t  .modalimg {position: fixed; top: 10%; left: 5%;  transform: translate(-2%, 0%);}\n\n\t}\n\t@media screen and (max-width:576px) {\n\t  #pop_content {\n\t\twidth: 100% !important;\n\t}\n\n\t}\n\t\n" }} />
          {/* slider image */}
          
          {/* slider image */}
          <input type="hidden" id="noticelist" defaultValue={0} />
          {/*Features code starts*/}
          <div className="container-fluid nopads">
            <div className="container nopads">
              <div className="outerbox">
                <div className="main-title col-lg-5 col-xs-12"><h1>Experience<span className="highlight"> The way of Banking</span></h1></div>
                <div className="clear" />
                {/*Four Features code starts*/}
                <div className="innrbox">
                  <div className="col-sm-3 col-xs-12 features-box features-box1">
                    <div className="circlebox featr1">
                        <img src="/images/mobile-banking.png" alt="mobile icon" />
                    </div>
                    <div className="innr-title hidden-sm hidden-lg hidden-md"><h2>Mobile Banking</h2></div>
                    <div className="featur_data">
                      <div className="innr-title hidden-xs"><h2>Mobile Banking</h2></div>
                      <div className="innrlist">
                        <ul>
                          <li>Fast, Easy &amp; Secure Banking</li>
                          <li>Anywhere Anytime Banking</li>
                          <li>Cashless banking</li>
                        </ul>
                        <span className="morebutn"><a data-ref="https://www.littlerock.com/mobile-banking">know more</a></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3 col-xs-12 features-box features-box2">
                    <div className="circlebox featr2">
                        <img src="/images/digital-economy.png" alt="digital economy" />
                    </div>
                    <div className="innr-title hidden-sm hidden-lg hidden-md"><h2>Internet Banking</h2></div>
                    <div className="featur_data">
                      <div className="innr-title hidden-xs"><h2>Internet Banking</h2></div>
                      <div className="innrlist">
                        <ul>
                          <li>Manage your Account conveniently</li>
                          <li>Fund Transfer at a click</li>
                          <li>Safe and Secure</li>
                        </ul>
                        <span className="morebutn"><a data-ref="https://www.littlerock.com/internet-banking">know more</a></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3 col-xs-12 features-box features-box3">
                    <div className="circlebox featr3">
                        <img src="/images/support.png" alt="support" />
                    </div>
                    <div className="innr-title hidden-sm hidden-lg hidden-md"><h2>Smile Centre</h2></div>
                    <div className="featur_data">
                      <div className="innr-title hidden-xs"><h2>Smile Centre</h2></div>
                      <div className="innrlist">
                        <ul>
                          <li>24*7 Toll Free Number</li>
                          <li>Email us at Smile@suryodaybank.com</li>
                        </ul>
                        <span className="morebutn"><a data-ref="https://www.littlerock.com/smile-centre">know more</a></span>
                      </div>
                    </div>
                    <div className="clear" />
                  </div>
                  <div className="col-sm-3 col-xs-12 features-box features-box3">
                    <div className="circlebox featr1">
                        <img src="/images/mobile-banking.png" alt="mobile icon 2" />
                    </div>
                    <div className="innr-title hidden-sm hidden-lg hidden-md"><h2>Mobile BC</h2></div>
                    <div className="featur_data">
                      <div className="innr-title hidden-xs"><h2>Mobile BC</h2></div>
                      <div className="innrlist">
                        <ul>
                          <li>An extended arm of the branches </li>
                          <li>Transactions by customers anytime at nearest BC </li>
                          <li>Real time settlement</li>
                        </ul>
                        <span className="newmblstyle" style={{marginTop: '10px'}}><a data-ref="https://www.littlerock.com/suryoday-mobile">know more</a></span>
                        
                      </div>
                    </div>
                  </div>	
                </div>
              </div>
              {/*Four Features code ends*/}
              <div className="clear" />
              {/*Open An Account code starts*/}
              <span className="acc-butn">
                <a data-ref="https://www.littlerock.com/account/signup">Apply Now</a>
              </span>
              {/*Open An Account code ends*/}
              <div className="clear" />
            </div>
          </div>
          {/*<div class="modal fade in" role="dialog" id="diwali_popup_image" style="z-index: 999999;height: 200px;">
      <!--<div class="modalimg" > */}
          {/* <div class="modal-content pop_content" id="pop_content" style="background: no-repeat; !important"> */}
          {/*<div class="modal-body" id="pop_content">   */}         
          {/*<button type="button" class="close" data-dismiss="modal" id="close_img">×</button>   
                <a href="/account/signup">   
                  <img class="img-responsive" src="/assets/images/RD_Square_Tile_1.jpg" alt="logo" id="imgclass">
                </a>
              </div> 
          <!-- </div> */}
          {/*</div>*/}
          {/* Customer Care code starts */}
          <style nonce dangerouslySetInnerHTML={{__html: "\n  .cursor-pointer{cursor: pointer;}\n  .display-none{display: none;}\n  .inv_cls{   padding-bottom: 6px !important;}\n  \n  \n  .modal-content-new{\n\t\t\tbackground-color: #fefefe;\n\t\t\tmargin: auto;\n\t\t\tpadding: 20px;\n\t\t\tborder: 1px solid #888;\n\t\t\twidth: 85%;}\n" }} />
          <div className="care">
            <div className="feedbackk">
              {/* Code for feedback form starts from here */}
              <div className="form-feedback nopad feedbcakht">
                <h1>Website Feedback <span className="ionicons cross_btn"></span></h1>
                <form id="form">
                  <input type="hidden" name="csrf_test_name" defaultValue="6ec93c676629c9ded3b7c4f45f7f1df9" />
                  <div className="cp-bxcs col-sm-12 col-xs-12 clear nopad">
                    {/* Identity Details starts */}
                    {/* Happy with your experience starts */}
                    <div className="shre-mrkt border-hegth cap-a col-xs-12 feedback1">
                      <div className="col-xs-12 exc_cust">Are you happy with your experience on our website today?</div>
                      <div className="contrlslde">
                        <div className="sldimge hidden-xs">
                          <img src="./SuryodayBank _ Home_files/scale-e.jpg" alt="scale" />
                        </div>
                        <div className="col-xs-12 scalebox">
                          <div className="scalediv">
                            {/*<img src="images/scale.jpg" alt="scale">*/}
                            <div id="slider-range-max" className="ui-slider ui-corner-all ui-slider-horizontal ui-widget ui-widget-content"><div className="ui-slider-range ui-corner-all ui-widget-header ui-slider-range-max" style={{width: '100%'}} /><span tabIndex={0} className="ui-slider-handle ui-corner-all ui-state-default" style={{border: 0, background: '#DB1922 !important', fontWeight: 'bold'}} /></div>
                          </div>
                        </div>
                      </div>
                      <div className="n-slde">
                        <input type="text" id="website_experience" readOnly />
                      </div>
                      <div className><label id="ratingmsg" className="fdkmsg display-none">Please rate us with higher ratings *</label></div>
                      {/* Kindly share the reason for rating provided starts */}
                      <div className="shre-mrkt border-hegth cap-a col-xs-12">
                        <div className="cap-a col-sm-12 col-xs-12 nopads feedbackcap">
                          <textarea className="txtbxval" placeholder="Write reason for rating provided" id="rating_reason" defaultValue={""} />
                          <label id="reasonmsg" className="fdkmsg display-none">Please provide us reason *</label>
                        </div>
                      </div>
                      {/* Kindly share the reason for rating provided ends */}
                      <div className="butoncontrl">
                        <div className="fr_uns col-sm-8 col-xs-12 nopads hidden-xs" />
                        <div className="fr_uns col-sm-4 col-xs-12 nopads">
                          <input type="button" className="task-s nextcl" defaultValue="Next >" id="next-f1" />
                        </div>
                      </div>
                    </div>
                    {/* Happy with your experience ends */}
                    {/* Please share your suggestions for improvement  starts */}
                    <div className="shre-mrkt border-hegth cap-a col-xs-12 feedback3" style={{display: 'none'}}>
                      <div className="col-xs-12 exc_cust">Please share your suggestions for improvement</div>
                      <div className="cap-a col-sm-12 col-xs-12 nopads">
                        <textarea className="txtbxval txtareawdth" placeholder="Write your suggestions for improvement" id="suggestions" defaultValue={""} />
                        <label id="suggestionmsg" className="fdkmsg display-none">Please provide us suggestions *</label>
                      </div>
                      <div className="butoncontrl">
                        <div className="fr_uns col-sm-4 col-xs-6 nopads">
                          <input type="button" className="task-s prevcl" defaultValue="< Prev" id="prev-f2" />
                        </div>
                        <div className="fr_uns col-sm-4 col-xs-12 nopads hidden-xs" />
                        <div className="fr_uns col-sm-4 col-xs-6 nopads">
                          <input type="button" className="task-s nextcl" defaultValue="Next >" id="next-f3" />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                {/* Please share your suggestions for improvement ends */}
                {/* Please Enter Your Contact Details starts */}
                <div className="shre-mrkt border-hegth cap-a col-xs-12 feedback4" style={{display: 'none'}}>
                  <form>
                    <div className="col-xs-12 exc_cust">Please Enter Your Contact Details</div>
                    <div className="cap-a col-xs-12 pad-10rg">
                      <input className="txtbxval" name="Name" placeholder="Enter Name*" onkeypress="return ischarKey(event)" id="name" type="text" autoComplete="off" />
                    </div>
                    <div className="cap-a col-xs-12 pad-10rg">
                      <input className="txtbxval" name="Email" placeholder="Enter Email Id*" id="emailfdk" type="text" autoComplete="off" />
                    </div>
                    <div className="cap-a col-xs-12 pad-10rg">
                      <input className="txtbxval" name="Mobile" placeholder="Enter Mobile Number*" onkeypress="return isNumberKey(event)" id="mobileno" maxLength={10} type="text" autoComplete="off" />
                    </div>
                    <div className="butoncontrl">
                      <div className="fr_uns col-sm-4 col-xs-4 nopads">
                        <input type="button" className="task-s prevcl" defaultValue="< Prev" id="prev-f3" />
                      </div>
                      <div className="fr_uns col-sm-4 col-xs-4 nopads breset">
                        <input type="reset" className="task-s nextcl" defaultValue="Reset" id="reset" />
                      </div>
                      <div className="fr_uns col-sm-4 col-xs-4 nopads bsubmit">
                        <input type="button" className="task-s nextcl" defaultValue="Submit" id="footer_feed_submit" />
                      </div>
                    </div>
                    {/* Please Enter Your Contact Details ends */}
                    <div className="shre-mrkt border-hegth cap-a col-xs-12 feedback5" style={{display: 'none'}}>
                      <div className="col-sm-4 fdbckimg nopad hidden-xs">
                        <img src="./SuryodayBank _ Home_files/fedbackmsg.png" alt="feedbacktankyou" />
                      </div>
                      <div className="col-sm-8 fdbckstr nopad">
                        {/* <div className="ionicons fedbckicon"></div> */}
                        <div className="fedbcktext">for submitting your feedback. We will get back to you shortly.</div>
                        <div className="fedbcktextmob">Thank you for submitting your feedback. We will get back to you shortly.</div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* Code for feedback form ends here */}
            </div>
          </div>
          {/*Customer Care code starts*/}
          <div className="clear" />
          {/* Top Lnks code starts */}
          {/* Top Lnks code ends */}
          {/* </div> */}
          {/* Features code ends */}
          {/* </div> */}
          {/* </div> */}
          {/*Top Lnks code starts*/}
          <div className="scll-top ionicon" style={{display: 'none'}}></div>
          {/*Top Lnks code ends*/}
          {/*Quick Lnks code starts*/}
          {/*<div class="quickbox">*/}
          <div id="footid" className="quickbox">
            <div className="container nopads">
              <div className="main-title">
                <h2>Quick 
                  <span className="highlight">Links</span>{/*<span class="dropdwn ionicon">&#xf123;</span>*/}
                  <span className="dropdwn2 ionicon"></span>
                </h2>
              </div>
              <div className="quicklinks">
                <a className="about_us" data-ref="https://www.littlerock.com/about-us">About Us</a>
                <a className="career_lnk" data-ref="https://www.littlerock.com/careers">Careers</a>
                <a className="media_lnk" data-ref="https://www.littlerock.com/media-news">Media News</a>
                <a className="our_partner_lnk" data-ref="https://www.littlerock.com/Institutional-Investors">Institutional Investors</a>
                <a data-ref="https://www.littlerock.com/schedule-of-charges">Schedule Of Charges</a>
                <a data-ref="https://www.littlerock.com/rate-of-interest">Rate of Interest</a>
                <a data-ref="https://www.littlerock.com/security-tips">Security Tips</a>
                <a data-ref="https://www.littlerock.com/regulatory-disclosures">Regulatory Disclosures</a>
                <a data-ref="https://www.littlerock.com/assets/pdf/Terms_and_Conditions_FY_23_24_Ver_1.pdf">Terms and Conditions</a>
                <a data-ref="https://www.littlerock.com/assets/pdf/Privacy-Policy-March-2023.pdf">Privacy Policy</a>
                <a className="home_center" data-ref="https://www.littlerock.com/Forms-centre">Forms Centre</a>
                <a className="home_center" data-ref="https://www.littlerock.com/pdf/Beware-of-Money-Mule-Frauds.pdf">Beware of Money Mule Frauds</a>
                <a className="career_lnk" data-ref="https://www.littlerock.com/Blogs">Blogs</a>
                <div className="main-title">
                  <h2 className="inv_cls">Investor   
                    <span className="highlight">Relations</span>
                    <span className="dropdwn2 ionicon"></span>	
                  </h2>
                  <a data-ref="https://www.littlerock.com/Corporate-Governance-Policies">Corporate Governance Policies</a>
                  <a data-ref="https://www.littlerock.com/Shareholders-Meeting">Shareholders Meeting</a>
                  <a className="financials-result" data-ref="https://www.littlerock.com/financials">Financials</a>
                  {/*  <a class="financials1" href="#">Financials</a>*/}
                  <a data-ref="https://www.littlerock.com/Disclosures-to-Stock-Exchanges">Disclosures to Stock Exchanges</a>	
                  <a data-ref="https://www.littlerock.com/assets/pdf/policies/Board-Committees.pdf">Board Committees</a>
                  <a data-ref="https://www.littlerock.com/investor-helpdesk">Investor Helpdesk</a>
                  <a data-ref="https://www.littlerock.com/Availing-Investor">Form for Availing Investor Services</a>
                  {/*<a class="financials-result" href="#">Financial Results</a> */}
                </div>
                <div className="clear" />
                <div className="browsocial">
                  <div className="col-sm-8 col-xs-12 netwrkig browsrnote hidden-xs"></div>
                  <div className="col-sm-4 col-xs-12 netwrkig nopads">
                    
                  </div>
                </div>
              </div>
              {/* Copyright and networking code ends */}
              <div className="copyrght-box">
                <div className="col-sm-6 col-xs-12 copyrght nopads newonecopy"><b>
                  </b></div>
                <div className="col-sm-6 col-xs-12 copyrght nopads ralign">Copyright &copy; 2020 The Bank of Little Rock. All rights reserved.</div>
              </div>
            </div>
            {/* Copyright and networking code ends */}
          </div>
          {/* Quick Lnks code ends */}
          {/* </div> */}
          {/*Code ends*/}
          {/* Modal Div Start */}
          <div id="myModalfdk" className="modal">
            {/* Modal content */}
            <div className="modal-content" id="mymodalfdkk">
              <span className="close">×</span>
              <p id="demo2" />
              <button type="button" className="btn btn-default btnclose" data-dismiss="modal">OK</button>
            </div>
          </div>
          {/* Modal Div End */}
          {/* new start investor pop container */}
          <div className="modal " id="videomodal" role="dialog">
            <div className="modal-dialog video-width modal-dialogtest">
              <div className="modal-content covid-popup newpp">
                <button type="button" className="close closefinantial" data-dismiss="modal">×</button>
                {/*<iframe src="#" height="500" width="100%" scrolling="no" ></iframe>*/}
                <h3 className="text-center">DISCLAIMER</h3>
                <div className="ScrollStyle">
                  <p className="popupp">
                    The audited financial statements of (“Bank”), as at and for the financial years ended March 31, 2018, March 31, 2019 and March 31, 2020 and the audit reports thereon dated May 30, 2018, May 29, 2019 and May 27, 2020, respectively (collectively, the “Financial Statements”), have been uploaded here by the Bank solely to comply with the requirements specified in the Securities and Exchange Board of India (Issue of Capital and Disclosure Requirement) Regulations, 2018, as amended (“SEBI ICDR Regulations”). 
                  </p>
                  <p className="popupp">
                    Please note that the Financial Statements do not constitute a draft red herring prospectus, red herring prospectus or final prospectus, a statement in lieu of a prospectus, an offering circular, an offering memorandum, an advertisement, an offer or a solicitation of any offer or an offer document to purchase or sell any securities under the Companies Act, 2013, the SEBI ICDR Regulations, or any other applicable law in India or elsewhere in the world. The Financial Statements should not be considered as part of information that any investor should consider to subscribe for or purchase any securities of the Bank and should not be relied upon or used as a basis for any investment decision. Anyone placing reliance on the Financial Statements, or any other information on the website of the Bank would be doing so at their own risk.
                  </p>
                  <p className="popupp">
                    The information contained herein does not constitute an offer for sale of, or solicitation of an offer to buy, the securities of the Bank in the United States or in any other jurisdiction. Securities may not be offered or sold in the United States absent registration or an exemption from registration under the U.S. Securities Act of 1933, as amended. 
                  </p>
                  <p className="popupp">
                    While care has been taken in preparing the Financial Statements, none of the Bank or any of its advisors, nor any book running lead managers or selling shareholders, nor any of their respective employees, directors, affiliates, agents or representatives accept any liability whatsoever for any loss howsoever arising from any information presented or contained in the Financial Statements, or the opinions expressed therein. None of the foregoing shall be liable for any direct or indirect losses arising from the use thereof and the viewers are requested to use the information contained herein at their own risk. 
                  </p>
                  <p className="popupp">
                    These Financial Statements should not be copied, reproduced, re-circulated, re-distributed, published or advertised in any media, website or otherwise, in any form or manner, in part or as a whole, without the express consent in writing from the Bank. Any unauthorized use of the Financial Statements contained herein is prohibited. Accordingly, any persons in possession of the aforesaid should inform themselves about and observe any such restrictions. Information contained in the Financial Statements is provided “as is” without warranty of any kind, either expressed or implied, including any warranty of fitness for a particular purpose. No reliance should be placed on, the accuracy, fairness or completeness of the information presented or contained in these Financial Statements. Further, past performance is not necessarily indicative of future results. Any opinion expressed on the Financial Statements are subject to change without notice. 
                  </p>
                  <p className="popupp">
                    The Financial Statements are only current as of the dates specified therein and neither the Bank nor any of its respective advisors or representatives is under any obligation to update you in the event the information in the Financial Statements becomes stale or inaccurate. You must make your own assessment of the relevance, accuracy and adequacy of the information contained in the Financial Statements and must make such independent investigation as you may consider necessary or appropriate for such purpose. 
                  </p>
                </div><br /><br />
                <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 hidden-xs">
                  <div className="col-lg-6 col-sm-6 col-md-6  text-right">
                    <a data-ref="https://www.littlerock.com/Annual-Report"><button className="popbtn1" type="button">I have read and accept</button></a>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-md-6  text-left">
                    <button className="popbtn2" name="popuphidebtn" type="button">I do not accept</button>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 hidden-lg hidden-sm hidden-md">
                  <div className="col-lg-6 col-sm-6 col-md-6  text-center">
                    <a data-ref="https://www.littlerock.com/Annual-Report"> <button className="popbtn1" type="button">I have read and accept</button></a>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-md-6  text-center mblbtnpadd">
                    <button className="popbtn2" name="popuphidebtn" type="button">I do not accept </button>
                  </div>
                </div><br /><br />
              </div>
            </div> 
          </div>
          {/* new end */}
          {/* financial model */}
          <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog1" role="document">
              <div className="modal-content-new">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                  <h3 className="text-center">DISCLAIMER</h3>
                </div>
                <div className="modal-body">
                  <div className="ScrollStyle">
                    <p className="popupp">
                      The audited financial statements of (“Bank”), as at and for the financial years ended March 31, 2018, March 31, 2019 and March 31, 2020 and the audit reports thereon dated May 30, 2018, May 29, 2019 and May 27, 2020, respectively (collectively, the “Financial Statements”), have been uploaded here by the Bank solely to comply with the requirements specified in the Securities and Exchange Board of India (Issue of Capital and Disclosure Requirement) Regulations, 2018, as amended (“SEBI ICDR Regulations”). 
                    </p>
                    <p className="popupp">
                      Please note that the Financial Statements do not constitute a draft red herring prospectus, red herring prospectus or final prospectus, a statement in lieu of a prospectus, an offering circular, an offering memorandum, an advertisement, an offer or a solicitation of any offer or an offer document to purchase or sell any securities under the Companies Act, 2013, the SEBI ICDR Regulations, or any other applicable law in India or elsewhere in the world. The Financial Statements should not be considered as part of information that any investor should consider to subscribe for or purchase any securities of the Bank and should not be relied upon or used as a basis for any investment decision. Anyone placing reliance on the Financial Statements, or any other information on the website of the Bank would be doing so at their own risk.
                    </p>
                    <p className="popupp">
                      The information contained herein does not constitute an offer for sale of, or solicitation of an offer to buy, the securities of the Bank in the United States or in any other jurisdiction. Securities may not be offered or sold in the United States absent registration or an exemption from registration under the U.S. Securities Act of 1933, as amended. 
                    </p>
                    <p className="popupp">
                      While care has been taken in preparing the Financial Statements, none of the Bank or any of its advisors, nor any book running lead managers or selling shareholders, nor any of their respective employees, directors, affiliates, agents or representatives accept any liability whatsoever for any loss howsoever arising from any information presented or contained in the Financial Statements, or the opinions expressed therein. None of the foregoing shall be liable for any direct or indirect losses arising from the use thereof and the viewers are requested to use the information contained herein at their own risk. 
                    </p>
                    <p className="popupp">
                      These Financial Statements should not be copied, reproduced, re-circulated, re-distributed, published or advertised in any media, website or otherwise, in any form or manner, in part or as a whole, without the express consent in writing from the Bank. Any unauthorized use of the Financial Statements contained herein is prohibited. Accordingly, any persons in possession of the aforesaid should inform themselves about and observe any such restrictions. Information contained in the Financial Statements is provided “as is” without warranty of any kind, either expressed or implied, including any warranty of fitness for a particular purpose. No reliance should be placed on, the accuracy, fairness or completeness of the information presented or contained in these Financial Statements. Further, past performance is not necessarily indicative of future results. Any opinion expressed on the Financial Statements are subject to change without notice. 
                    </p>
                    <p className="popupp">
                      The Financial Statements are only current as of the dates specified therein and neither the Bank nor any of its respective advisors or representatives is under any obligation to update you in the event the information in the Financial Statements becomes stale or inaccurate. You must make your own assessment of the relevance, accuracy and adequacy of the information contained in the Financial Statements and must make such independent investigation as you may consider necessary or appropriate for such purpose. 
                    </p>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 hidden-xs">
                    <div className="col-lg-6 col-sm-6 col-md-6  text-right">
                      <a data-ref="https://www.littlerock.com/financials"><button className="popbtn1" type="button">I have read and accept</button></a>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-md-6  text-left">
                      <button className="popbtn2" name="popuphidebtn" type="button" data-dismiss="modal">I do not accept</button>
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 hidden-lg hidden-sm hidden-md">
                    <div className="col-lg-6 col-sm-6 col-md-6  text-center">
                      <a data-ref="https://www.littlerock.com/financials"> <button className="popbtn1" type="button">I have read and accept</button></a>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-md-6  text-center mblbtnpadd">
                      <button className="popbtn2" name="popuphidebtn" type="button" data-dismiss="modal">I do not accept </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* financial model end */}	
          <div id="vizury-notification-container" />
        </div>
    )
}
export default Login;
import React, { useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Context } from "../context";

const Header = () => {
    const navigate = useNavigate();
    const {
		userDetail,
        setUserDetail,
	} = useContext(Context);
    useEffect(() => {
        console.log(userDetail);
    }, [userDetail]);
    const logout = () => {
        setUserDetail(null);
        localStorage.removeItem('bank_user');
        navigate("/");
    }
  return (
    <div className='py-2 header'>
        <div className='container'>
            <div className='row align-items-center'>
                <div className='col-md-4'>
                    <Link to="/" style={{width:'80px', height: '69px', display: 'block'}}>
                        <img src="/images/logo.png" alt="logo" style={{width: "80px"}} />
                    </Link>
                </div>
                <div className='col-md-8'>
                    <div className='row'>
                        <ul className='navList'>
                            <li>
                                <a href="https://diro.io/" target="_blank" rel="noreferrer">Bank Home</a>
                            </li>
                            <li>
                                <a href="https://diro.io/" target="_blank" rel="noreferrer">About Us</a>
                            </li>
                            <li>
                                <a href="https://diro.io/" target="_blank" rel="noreferrer">Customer Care</a>
                            </li>
                            <li>
                                <a href="https://diro.io/" target="_blank" rel="noreferrer">Find ATM/Branch</a>
                            </li>
                            {userDetail?.key ? <li>
                                <button className='btn btn-warning' onClick={logout}>Logout</button>
                            </li> : "" }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Header

import React, { useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { Context } from "../context";
import Header from "./Header";
import Login from "./Login";
import Home from "./Home";
import Footer from "./Footer";
import Dashboard from "./Dashboard";
const Layout = (props) => {
    const {
		setUserDetail
	} = useContext(Context);
    const cleanupLocalStorage = () => {
        const storedData = localStorage.getItem('bank_user');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            setUserDetail(parsedData);
            if (parsedData.timestamp <= Date.now()) {
                localStorage.removeItem('bank_user');
            }
        }
    }
    useEffect(() => {
        cleanupLocalStorage();
    }, []);
    return (
        <>
            {/* <Header /> */}
            <Routes>
				<Route path="/" exact={true} element={<Home />} />
				<Route path="/login" exact={true} element={<Login />} />
				<Route path="/dashboard" element={<Dashboard />} />
            </Routes>
            {/* <Footer /> */}
        </>
    )
}
export default Layout;